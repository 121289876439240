import React from "react";
import styled from "styled-components";
// import { Link } from "gatsby"
import Text, { Grid } from "../components/Theme";
import Layout from "../components/layout";
import Header from "../components/Header";
import media from "styled-media-query";

const ContactModalContainer = styled.div`
    background-color: #7497a1;
    top: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
`;
const IntroText = styled.div`
    ${Grid};
    grid-column: 1/13;
    padding: 0 !important;
    margin-bottom: 30px;
    margin-top: 50px;
    ${media.lessThan("large")`
        padding-top: 60px;
    `}
`;
const ContactModal = styled.form`
    ${Grid};
    min-height: 100vh;
    input {
        background-color: none;
        border: 0px;
    }
`;

const FormText = styled(Text)`
    color: #fff;
    align-self: start;
    margin-bottom: 40px;
    .msg {
        align-self: start;
    }
    label {
        color: #fff;
        margin-top: 24px;
    }
    ${media.lessThan("medium")`
        margin-bottom: 10px;
    `}

    textarea::placeholder, input::placeholder {
        color: #fff;
        opacity: 0.2;
    }
    &:last-child {
        align-self: start;
    }
`;
const FormActions = styled.ul`
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    background: #fff;
    padding: 0px 0;
    height: 100px;
    line-height: 100px;
    margin: 0;
    input {
        background-color: #fff;
        border: 0;
        right: 80px;

        position: absolute;
        display: block;
        margin: 0;
        height: 100%;
        bottom: 0;
        font-family: "am";
        text-transform: uppercase;
        text-align: right;
    }
`;

const Field = styled.input`
    color: #fff;
    border: 0;
    background-color: transparent;
    box-shadow: -180px 0 0px 0px #7497a1;
    margin-bottom: 20px;
    align-self: start;

    &:focus {
        outline: none;
        box-shadow: 0px 0 0px 0px #7497a1;
    }
`;
const Area = styled.textarea`
    color: #fff;
    border: 0;
    background-color: transparent;
    box-shadow: -180px 0 0px 0px #7497a1;
    align-self: start;
    &:focus {
        outline: none;
        box-shadow: 0px 0 0px 0px #7497a1;
    }
`;
const Label = styled.label`
    margin-top: 20px;
`;

const License = () => (
    <Layout parent="contact-page">
        <Header content_color="white" />
        <ContactModalContainer>
            <ContactModal
                name="license"
                method="POST"
                action="https://formspree.io/meqqpqdv">
                <IntroText>
                    <FormText sub col="2/7">
                        Interested in licensing imagery?
                        <br />
                        Get in touch with us here:
                    </FormText>
                </IntroText>

                <p className="hidden">
                    Don’t fill this out if you're human:{" "}
                    <input type="hidden" name="bot-field" value="contact" />
                    <input type="hidden" name="form-name" value="license" />
                </p>
                <FormText sub col="1/2">
                    <Label className="caption">Name</Label>
                </FormText>
                <FormText sub col="2/6">
                    <Field type="text" name="Name: " placeholder="Your Name" />
                </FormText>
                <FormText sub col="6/7">
                    <Label className="caption">Email</Label>
                </FormText>
                <FormText sub col="7/12">
                    <Field type="email" name="email" placeholder="Email" />
                </FormText>

                <FormText sub col="1/2">
                    <Label className="caption">Company</Label>
                </FormText>
                <FormText sub col="2/6">
                    <Field
                        type="text"
                        name="Company: "
                        placeholder="Your Company"
                    />
                </FormText>
                <FormText sub col="6/7">
                    <Label className="caption">Message</Label>
                </FormText>
                <FormText sub col="7/12">
                    <Area name="Message: " placeholder="Your Message"></Area>
                </FormText>

                <FormActions>
                    <input
                        type="submit"
                        value="Send Message"
                        className="special"
                    />
                </FormActions>
            </ContactModal>
        </ContactModalContainer>
    </Layout>
);

export default License;
